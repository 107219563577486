import React from 'react';
import { Link } from 'react-router-dom';
import {
	DASHBOARD,
	CHOOSE_QUIZ,
	TAKE_FLASHCARDS,
	ADD_FLASHCARD,
	FLASHCARDS_LIST,
	STATS,
} from '../../../utils/ROUTES';

const NavLinksUser = () => {
	return (
		<React.Fragment>
			<ul id='dropdown1' className='dropdown-content'>
				<li>
					<Link to={DASHBOARD}>
						<i className='material-icons'>dashboard</i>
						Dashboard
					</Link>
				</li>
				<li className='divider'></li>

				<li>
					<Link to={CHOOSE_QUIZ}>
						<i className='material-icons'>question_answer</i>
						Examínate
					</Link>
				</li>

				<li className='divider'></li>
				<li>
					<Link to={TAKE_FLASHCARDS}>
						<i className='material-icons'>indeterminate_check_box</i>
						Flashcards
					</Link>
				</li>
				<li>
					<Link to={FLASHCARDS_LIST}>
						<i className='material-icons'>format_list_bulleted</i>
						Edita
					</Link>
				</li>
				<li>
					<Link to={ADD_FLASHCARD}>
						<i className='material-icons'>add</i>
						Agrega
					</Link>
				</li>

				<li className='divider'></li>
				<li>
					<Link to={STATS}>
						<i className='material-icons'>insights</i>
						Tu Data
					</Link>
				</li>
			</ul>
		</React.Fragment>
	);
};

export default NavLinksUser;
