import React, { useEffect } from 'react';

function TestRecordDetails({ records, onLoadDone }) {
	useEffect(() => {
		onLoadDone(true);

		return () => {
			onLoadDone(false);
		};
	}, []);

	const totalPreviousTests = records.length;
	const totalCorrectas = records.reduce((sum, currentValue) => {
		return sum + currentValue.score;
	}, 0);
	const totalPreguntas = records.reduce((sum, currentValue) => {
		return sum + currentValue.testLength;
	}, 0);
	const lastTest = {
		...records.sort((a, b) => {
			return b.date - a.date;
		})[0],
	};
	const lastScore = Math.round((lastTest.score / lastTest.testLength) * 100);
	const lastTestLength = lastTest.testLength;
	const lastTestType = lastTest.testType;

	return (
		<div className='row'>
			{/* Evaluaciones en total */}
			<div className='col m3 s6'>
				<div className='card-panel center bg-alternate3 history-card'>
					<strong style={{ fontSize: 'xx-large' }}>{totalPreviousTests}</strong>
					<p className='fsize-small'>
						{totalPreviousTests === 1
							? 'Evaluación en total'
							: 'Evaluaciones en total'}
					</p>
				</div>
			</div>
			{/* Reducer para promedio de notas */}
			<div className='col m3 s6'>
				<div className='card-panel center bg-brand txt-brand-white history-card'>
					<strong style={{ fontSize: 'xx-large' }}>
						{`${Math.round(
							((totalCorrectas + Number.EPSILON) / totalPreguntas) * 100
						)}%`}
					</strong>
					<p className='fsize-small'>Nota Promedio</p>
				</div>
			</div>
			{/* Puntuación ultimo examen */}
			<div className='col m3 s6'>
				<div className='card-panel center bg-alternate2 txt-brand-white history-card'>
					<strong style={{ fontSize: 'xx-large' }}>
						{`${Math.round(((lastScore + Number.EPSILON) / 100) * 100)}%`}
					</strong>
					<p className='fsize-small'>Nota del último examen</p>
				</div>
			</div>

			<div className='col m3 s6'>
				<div
					style={{ marginLeft: window.innerWidth > 790 ? '2rem' : '0.5rem' }}
					className='history-card'>
					<blockquote style={{ borderLeftColor: 'var(--brand-accent)' }}>
						{lastTestType === 'otro'
							? `Tu último examen fue personalizado con ${lastTestLength} preguntas`
							: `Tu último examen fue un ${lastTestType} de ${lastTestLength} preguntas`}
					</blockquote>
				</div>
			</div>
		</div>
	);
}

export default TestRecordDetails;
