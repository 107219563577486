import React, { createContext, useState, useContext, useEffect } from 'react';
// import { Redirect } from 'react-router-dom';
import firebase, { firebaseAuth, logError, logEvent } from '../config/fbConfig';
import {
	deleteAllCookies,
	redirectToHome,
	redirectToVerifyEmail,
} from '../../helpers';
/*eslint-disable*/
import {
	DEBUG_BLUE,
	DEBUG_GREEN,
	DEBUG_RED,
	DEBUG_YELLOW,
	prepmer_price,
} from './types';
/*eslint-enable*/
import { AdminContext } from './adminContext';
import { consoleError, consoleInfo } from '../../debugging';
import indexedDBHelper from '../../indexedDBHelper';
import { createObjectStore } from '../../indexedDBHelpers';
export const AuthContext = createContext();

const AuthContextProvider = (props) => {
	const { getCurrentEnurm, nextEnurm, updateTotalSales, updateTotalSignups } =
		useContext(AdminContext);

	// FIXME: "Verificando usuario y dispositivo"

	const [user, setUser] = useState({});
	const [testSelections, setTestSelections] = useState({});
	const [userAuthState, setUserAuthState] = useState(false);
	const [userTestRecords, setUserTestRecords] = useState([]);
	const [loading, setLoading] = useState(false);
	const [webAppVersion, setWebAppVersion] = useState(0);
	const [updateWebApp, setUpdateWebApp] = useState(false);

	let today = Date.now();
	const ONE_DAY_IN_SECONDS = 86400;
	const THIRTY_DAYS = ONE_DAY_IN_SECONDS * 30;
	// const ONE_YEAR_IN_MS = ONE_DAY_IN_SECONDS * 365 * 1000;
	const lastRecordsPullDate = new Date(
		JSON.parse(localStorage.getItem('prepmed-last-records-pull-date'))
	);

	//~ Web App Version
	//#region
	const deleteIndexedDB = async (database) => {
		let DBDeleteRequest = window.indexedDB.deleteDatabase(database);

		DBDeleteRequest.onerror = function (event) {
			consoleInfo('Error deleting database.');
		};

		DBDeleteRequest.onsuccess = function (event) {
			consoleInfo('Database deleted successfully');
		};
	};

	const getLatestWebVersionNumber = async () => {
		let webAppV = 0;
		await firebase
			.firestore()
			.collection('business')
			.doc('webAppVersion')
			.get()
			.then((doc) => {
				webAppV = doc.data();
				setWebAppVersion(doc.data());
			});

		return webAppV;
	};

	const hasLatestWebAppVersion = async () => {
		let version = await getLatestWebVersionNumber();
		if (user.hasOwnProperty('appVersionWeb')) {
			if (version.number > user.appVersionWeb) {
				setUpdateWebApp(true);
				// deleteIndexedDB('prepmed-questions');
				if (user.isAdmin) {
					updateUserWebAppVersion(false);
				}
				// indexedDBHelper.deleteDB('prepmed-questions');
			}
			return;
		}

		if (!user.hasOwnProperty('appVersionWeb') && webAppVersion === 0) {
			updateUserWebAppVersion(false, version.number);
			return;
		}
	};

	const updateUserWebAppVersion = async (bool) => {
		let version = await getLatestWebVersionNumber();
		if (bool) {
			firebase
				.firestore()
				.collection('users')
				.doc(user.uid)
				.update({
					appVersionWeb: version.number,
				})
				.then(() => deleteAllCookies());
			return;
		} else {
			firebase.firestore().collection('users').doc(user.uid).set(
				{
					appVersionWeb: version.number,
				},
				{ merge: true }
			);
		}
	};
	//#endregion

	const getCurrentUserFromFirebaseAuth = async () => {
		setLoading(true);
		firebaseAuth.onAuthStateChanged(function (user) {
			if (user) {
				// User is signed in.
				updateUser(user);
				return true;
			} else {
				// No user is signed in.
				console.info('There was no user found.');
				return false;
			}
		});
		setLoading(false);
	};

	const isUserLoggedIn = () => {
		// !code below is working
		// user.uid &&
		// 	firebase
		// 		.firestore()
		// 		.collection('users')
		// 		.doc(user.uid)
		// 		.onSnapshot((doc) => {
		// 			setUserAuthState(doc.data().isLoggedIn);
		// 			// console.log(
		// 			// 	'%c Current User State: ',
		// 			// 	DEBUG_BLUE,
		// 			// 	userAuthState,
		// 			// 	Date.now()
		// 			// );
		// 		});
		// return userAuthState;
	};

	/**
	 * Gets a user from Firestore and updates localStorage
	 */
	const updateUser = (userInfo = user) => {
		// ! Should I clear the localStorage user?
		firebase
			.firestore()
			.collection('users')
			.doc(userInfo.uid)
			.get()
			.then((doc) => {
				// console.info('document:', doc);
				if (!doc.exists) {
					console.info("ERROR: Can't update user!");
					firebaseAuth.signOut();
					// throw new Error('Some error...');
					// window.location.replace('/contactanos');
				} else {
					setUser({
						...userInfo,
						...doc.data(),
					});

					makeIsLoggedInTrue(userInfo.uid, true);
				}
			});
		// sessionStorage.setItem('prepmed-uuid', `${userInfo.uid}`);
		localStorage.setItem('prepmed-uuid', `${userInfo.uid}`);
		localStorage.setItem('prepmed-LastLogInDate', JSON.stringify(Date.now()));
	};

	/**
	 * Takes in uid & obj
	 */
	const updateNewUserInformationInFirestore = (userID, data) => {
		firebase
			.firestore()
			.collection('users')
			.doc(userID)
			.update({
				...data,
			})
			.then(() => {
				console.log('Document successfully updated!');
			})
			.catch((error) => {
				// The document probably doesn't exist.
				console.error('Error updating document: ', error);
			});
	};

	const updateUserUsingLocalStorage = (uuid) => {
		firebase
			.firestore()
			.collection('users')
			.doc(uuid)
			.get()
			.then((doc) => {
				setUser({ ...doc.data() });
				window.location.replace('/dashboard');
			})
			.catch((err) => {
				console.log(err);
			});
	};

	//~ NEW SIGNUP
	// #region
	const newUserSignup = async (email, password) => {
		let result = {};
		result = await firebase
			.auth()
			.createUserWithEmailAndPassword(email, password)
			.then((userCredential) => {
				// Signed in
				let newUser = { email, password, ...userCredential.user };
				updateTotalSignups();

				// Create the new user in users collection
				createNewUser(newUser);
				return newUser;
			})
			.catch((error) => {
				// console.error(error);
				result = { ...error, signupErroredOut: true };
				return result;
			});

		return result;
	};

	const createNewUser = (newUser) => {
		firebase
			.firestore()
			.collection('users')
			.doc(newUser.uid)
			.set({
				email: newUser.email,
				password: newUser.password,
				signUpDate: new Date(),
				[`isClient_${nextEnurm}`]: false,
				[`membership_${nextEnurm}`]: {
					isPaid: false,
				},
				paymentCompleted: false,
			})
			.then(() => {
				if (process.env.NODE_ENV !== 'production') {
					console.info('NEW_SIGNUP: Creating user document.');
				}

				logEvent('NEW_SIGNUP');
			})
			.catch((err) => {
				if (process.env.NODE_ENV !== 'production') {
					console.info('SIGNUP_ERROR: onCreate of user document.', err);
				}

				logEvent(
					`SIGNUP_ERROR: on create of user document. - ${newUser.email} - ${err.code}`
				);
				logError(err, `Error creating user doc for ${newUser.email}`);

				deleteUserOnFailedSignup(newUser.email);
			});
	};

	const deleteUserOnFailedSignup = async (email) => {
		const currentUser = firebase.auth().currentUser;
		const userExists = await checkIfUserInCollection(currentUser, email);

		let result;
		if (!userExists) {
			result = await currentUser
				.delete()
				.then(() => {
					console.info('User deleted due to failed signup.');
					return 'Auth account deleted';
				})
				.catch((error) => {
					// console.info('Error deleting user due to failed signup.', error);
					return error;
				});
		}

		return result;
	};

	/**
	 * Checks if a user exists in the 'users' collection
	 */
	const checkIfUserInCollection = async (currentUser, userEmail) => {
		let userExists;

		if (currentUser) {
			userExists = await firebase
				.firestore()
				.collection('users')
				.doc(currentUser.uid)
				.get()
				.then((doc) => {
					if (doc.exists) {
						return true;
					} else {
						return false;
					}
				})
				.catch((error) => {
					console.log('Error getting document:', error);
				});
		} else {
			userExists = await firebase
				.firestore()
				.collection('users')
				.where('email', '==', userEmail)
				.get()
				.then((querySnapshot) => {
					let r = [];
					querySnapshot.forEach((doc) => {
						// doc.data() is never undefined for query doc snapshots
						console.log(doc.id, ' => ', doc.data());
						r.push(doc.data());
					});

					if (r.length > 0) return true;
					return false;
				})
				.catch((error) => {
					console.log('Error getting documents: ', error);
				});
		}

		return userExists;
	};

	const sendVerificationEmail = async () => {
		const thisUser = firebase.auth().currentUser;

		if (thisUser) {
			await thisUser.sendEmailVerification().then(() => {
				// console.info('Email verification sent!');
				// firebase
				// 	.auth()
				// 	.signOut()
				// 	.then(() => {
				// 		// console.info('User was signed out');
				// 		// redirectToVerifyEmail();
				// })
				// .catch((err) => {
				// 	console.log('SIGNOUT ERROR:', err);
				// });
			});
		}

		if (!thisUser) {
			await firebase.auth().signOut();
		}
	};
	//#endregion

	const signOut = () => {
		const currentPath = window.location.pathname;
		console.info(currentPath);
		const isConfirmed = window.confirm('¿Estas seguro de cerrar tu sesión?');
		console.info(isConfirmed);
		if (isConfirmed) {
			firebase
				.auth()
				.signOut()
				.then(() => {
					makeIsLoggedInTrue(localStorage.getItem('prepmed-uuid'), false);
					deleteAllCookies();
					redirectToHome();
				})
				.catch((err) => {
					console.log('SIGNOUT ERROR:', err);
					logEvent('SIGNOUT_ERROR', err);
					logError('SIGNOUT_ERROR', err, window.location.pathname);
				});
			return;
		}

		window.location.replace(currentPath);
	};

	const makeIsLoggedInTrue = async (userID, bool) => {
		await firebase
			.firestore()
			.collection('users')
			.doc(`${userID}`)
			.update({ isLoggedIn: bool });
		// .then(() => console.log(`User updated to isLoggedIn: ${bool}`));
	};

	const findUserByEmailAndMakeAdmin = (emailEntered) => {
		firebase
			.firestore()
			.collection('users')
			.where('email', '==', emailEntered)
			.get()
			.then((snapshot) => {
				if (snapshot.empty) {
					console.log('No matching documents.');
					return;
				}

				snapshot.forEach((doc) => {
					console.log(doc.id, '=>', doc.data());
					firebase
						.firestore()
						.collection('users')
						.doc(doc.id)
						.update({ isAdmin: true });
				});
			});
	};

	const updateCurrentTestSelections = (data) => {
		setTestSelections({ ...data });
	};

	/**
	 * Change email address - takes in email
	 */
	const changeEmailAddress = (email) => {
		var user = firebase.auth().currentUser;

		user
			.updateEmail(email)
			.then(function () {
				// Update successful.
				firebase
					.firestore()
					.collection('users')
					.doc(user.uid)
					.update({
						email: email,
					})
					.then(() => {
						updateUser();
						console.info('Email change success');
					})
					.catch((err) => {
						console.info(err);
					});
			})
			.catch(function (error) {
				// An error happened.
				console.info(error);
			});
	};

	/**
	 * Change password - takes in email
	 */
	const resetPassword = (email) => {
		return firebase
			.auth()
			.sendPasswordResetEmail(email)
			.then(function (e) {
				// Password reset email sent.
				return 'success';
			})
			.catch(function (error) {
				console.log('password rest FAILED', error);
				return 'error';
			});
	};

	//~ FREE TRIAL
	// #region
	/**
	 * Starts a 48 hour free trial, needs no arguments
	 */
	const freeTrialStart = () => {
		firebase
			.firestore()
			.collection('users')
			.doc(user.uid)
			.set(
				{
					freeTrialActive: true,
					freeTrialStartDate: new Date(),
					freeTrialExpired: false,
					[`membership_${nextEnurm}`]: {
						type: 'Free Trial',
					},
				},
				{ merge: true }
			)
			.then(() => {
				console.info('Free trial start success');
				add1FreeTrial();
				window.location.reload();
			})
			.catch((err) => {
				console.info(err);
			});
	};

	/**
	 * Stops the 48 hour free trial, needs no arguments
	 */
	const freeTrialEnd = () => {
		firebase
			.firestore()
			.collection('users')
			.doc(user.uid)
			.update({
				freeTrialActive: false,
				freeTrialExpired: true,
			})
			.then(() => {
				console.info('Free trial end success');
				remove1FreeTrial();
				window.location.reload();
			})
			.catch((err) => {
				console.info(err);
			});
	};

	const add1FreeTrial = () => {
		firebase
			.firestore()
			.collection('business')
			.doc(`enurm_${nextEnurm}`)
			.update({
				freeTrialsActive: firebase.firestore.FieldValue.increment(1),
			});
	};

	const remove1FreeTrial = () => {
		firebase
			.firestore()
			.collection('business')
			.doc(`enurm_${nextEnurm}`)
			.update({
				freeTrialsActive: firebase.firestore.FieldValue.increment(-1),
			});
	};
	//#endregion

	/// USER FLASHCARD RECORDS
	const storeFlashcardsResults = (results) => {
		firebase
			.firestore()
			.collection(`flashcardRecords_${user.enurmTargetYear}`)
			.doc()
			.set(
				{
					...results,
					user_uid: user.uid,
					date: new Date(),
				},
				{ merge: true }
			)
			.then(() => {})
			.catch((error) => {
				logError(error, error, window.location.pathname, user.uid);
				console.log('Error updating document. Please see console.');
				console.log(error);
			});
	};

	//~ USER TEST RECORDS
	//#region
	const getUserTestRecordsFromDB = () => {
		let user_testRecords = [];
		localStorage.removeItem('prepmed-user-test-records');
		localStorage.removeItem('prepmed-last-records-pull-date');

		firebase
			.firestore()
			.collection(`testRecords_${user.enurmTargetYear}`)
			.where('user_uid', '==', user.uid)
			.get()
			.then((querySnapshot) => {
				querySnapshot.forEach((doc) => {
					user_testRecords.push(doc.data());
				});

				setUserTestRecords(user_testRecords);

				// localStorage.setItem(
				// 	'prepmed-user-test-records',
				// 	JSON.stringify(user_testRecords)
				// );

				// localStorage.setItem(
				// 	'prepmed-last-records-pull-date',
				// 	JSON.stringify(today)
				// );
			})
			.catch((error) => {
				console.info('Error getting documents: ', error);
			});
	};

	const getUserTestRecords = async () => {
		let user_testRecords = [];

		let lsRecords = await JSON.parse(
			localStorage.getItem('prepmed-user-test-records')
		);

		// CHECKS LAST PULL DATE
		if ((today - lastRecordsPullDate) / 1000 > ONE_DAY_IN_SECONDS) {
			console.info('%c Fresh pull of test records required.', DEBUG_RED);
			localStorage.removeItem('prepmed-user-test-records');
			localStorage.removeItem('prepmed-last-records-pull-date');
			lsRecords = null;
		}

		// GETTING RECORDS
		if (lsRecords?.length > 0) {
			console.info('%c Getting records from local storage', DEBUG_GREEN);
			setUserTestRecords(lsRecords);
			return;
		} else {
			// user.testRecordsConverted
			// \?
			firebase
				.firestore()
				.collection(`testRecords_${user.enurmTargetYear}`)
				.where('user_uid', '==', user.uid)
				.get()
				.then((querySnapshot) => {
					querySnapshot.forEach((doc) => {
						user_testRecords.push(doc.data());
					});

					// console.info(
					// 	'%c Pulling from DB (testRecords_2021 collection)',
					// 	DEBUG_GREEN
					// );
					// console.info(user_testRecords);
					setUserTestRecords(user_testRecords);

					localStorage.setItem(
						'prepmed-user-test-records',
						JSON.stringify(user_testRecords)
					);

					localStorage.setItem(
						'prepmed-last-records-pull-date',
						JSON.stringify(today)
					);

					logEvent('Pulled User Test Records');
				})
				.catch((error) => {
					consoleError('Error getting test records', error, true);
				});
		}
	};

	const getUserTestRecords_optimized = async () => {
		let user_testRecords = [];

		localStorage.removeItem('prepmed-user-test-records');
		localStorage.removeItem('prepmed-last-records-pull-date');

		if (userTestRecords.length) {
			consoleInfo(
				'Did not call DB to get userTestRecords',
				{ userTestRecords },
				true
			);
			return;
		} else {
			// GETTING RECORDS
			firebase
				.firestore()
				.collection(`testRecords_${user.enurmTargetYear}`)
				.where('user_uid', '==', user.uid)
				.get()
				.then((querySnapshot) => {
					querySnapshot.forEach((doc) => {
						user_testRecords.push(doc.data());
					});

					setUserTestRecords(user_testRecords);

					consoleInfo('Getting user test records');
					logEvent(`Pulled Test Records for: ${user.uid}`);
				})
				.catch((error) => {
					consoleError('Error getting test records', error, true);
				});
		}
	};

	const storeUserTestResults = async (userID, currentTestResults) => {
		// SAVES TEST RESULTS AS AN ARRAY WITH NESTED OBJECTS
		const previousTestResults = localStorage.getItem(
			'prepmed-user-test-records'
		)
			? JSON.parse(localStorage.getItem('prepmed-user-test-records'))
			: [];
		// console.info('ls records:', previousTestResults);

		//~ STORE RECORDS
		await firebase
			.firestore()
			.collection('testRecords_2022')
			// TODO: debo agregarles "enurmTargetYear" a los new signups/payers y a los de last year
			// .collection(`testRecords_${user.enurmTargetYear}`)
			.doc()
			.set(
				{
					...currentTestResults,
					user_uid: userID,
					date: new Date(),
				},
				{ merge: true }
			)
			.then(function () {
				console.info(`Test results added to testRecords.`);
				localStorage.removeItem('prepmed-user-test-records');
				localStorage.setItem(
					'prepmed-user-test-records',
					JSON.stringify([
						...previousTestResults,
						{
							...currentTestResults,
							date: { seconds: Math.floor(Date.now() / 1000) },
						},
					])
				);

				let allRecords = [
					...previousTestResults,
					{
						...currentTestResults,
						date: { seconds: Math.floor(Date.now() / 1000) },
					},
				];

				// //~ UPDATE USER RECORDS
				updUserTestRecords(userID, currentTestResults, allRecords);

				return true;
			})
			.catch(function (error) {
				// The document probably doesn't exist.
				logError(error, error, window.location.pathname, user.uid);
				console.log('Error updating document. Please see console.');
				console.log(error);
			});
	};

	//~ UPDATE USER RECORDS
	const updUserTestRecords = (userID, currentTestResults, allRecords) => {
		console.info('updUserTestRecords:', userID, currentTestResults, allRecords);
		firebase
			.firestore()
			.collection('users')
			.doc(userID)
			.update({
				// testRecords: firebase.firestore.FieldValue.arrayUnion({
				// 	...currentTestResults,
				// 	date: new Date(),
				// }),
				lastTestDate: new Date(),
				lastTestScore: getLastTestScore(currentTestResults),
				avgGrade: getAverageGrade([...allRecords, currentTestResults]),
				totalTestRecords: getTotalTestRecords(allRecords),
				firstTestScore:
					!user.firstTestScore && getFirstTestRecord(currentTestResults),
			})
			.then(() => {
				console.info(`Test results successfully updated.`);
			});
	};

	//~ HELPERS TO STORE TEST RECORDS
	//#region
	const getTotalTestRecords = (test) => {
		// console.info('getTotalTestRecords', test.length < 0, test);
		if (test.length > 0) {
			return test.length + 1;
		} else {
			return 1;
		}
	};

	// TODO: I need to review these
	const getFirstTestRecord = (test) => {
		// console.info('getFirstTestRecord', test);
		if (user.testRecords) {
			return user.testRecords[0].score;
		} else {
			return test.score;
		}
	};

	const getLastTestScore = (test) => {
		// console.info('getLastTestScore', test);
		let score = (
			((test.score + Number.EPSILON) / test.testLength) *
			100
		).toFixed(2);

		return score;
	};

	const getAverageGrade = (alltests) => {
		console.info('getAverageGrade', alltests);
		let totalCorrectas, totalPreguntas;
		if (alltests) {
			totalCorrectas = alltests.reduce((sum, currentValue) => {
				return sum + currentValue.score;
			}, 0);
			totalPreguntas = alltests.reduce((sum, currentValue) => {
				return sum + currentValue.testLength;
			}, 0);
			return Math.round(
				((totalCorrectas + Number.EPSILON) / totalPreguntas) * 100
			);
		}
		return '';
	};
	//#endregion
	//#endregion

	const updateToPaidViaStripe = async (
		user,
		price,
		sessionId,
		source,
		currency
	) => {
		// const today = new Date();

		const isClient = `isClient_${nextEnurm}`;
		const membership = `membership_${nextEnurm}`;
		const payment = `paymentCompleted_${nextEnurm}`;

		firebase
			.firestore()
			.collection('users')
			.doc(user.uid)
			.update({
				[membership]: {
					isPaid: true,
					paymentDate: new Date(),
					paymentAmount: price,
					paymentSource: source,
					stripeCustomer: 'undefined',
					id: sessionId,
					paymentCurrency: currency,
					type: 'Customer',
					// expires: today.getTime() + ONE_YEAR_IN_MS,
					status: 'active',
				},
				[isClient]: true,
				[payment]: true,
				enurmTargetYear: nextEnurm,
				freeTrialActive: false,
				freeTrialExpirednp: false,
			})
			.then(() => {
				// console.info('User document updated with billing details.');
				updateTotalSales();

				if (process.env.NODE_ENV === 'production') {
					logEvent('SALE', user);
				}
				updateUser(user);
			})
			.catch((error) => {
				logEvent('ERROR_ON_SALE', user);
				console.error(error);
				setLoading(false);
				return error.message;
			});
	};

	//~ POST TEST
	//#region
	const storePostTestConsultation = (info) => {
		let field = `post_test_consultation_${user.enurmTargetYear}`;
		firebase
			.firestore()
			.collection('users')
			.doc(user.uid)
			.update({
				cedula: info.cedula,
				[field]: {
					// nota_final: info.nota_final,
					especialidad_target_1: info.especialidad_target_1,
					especialidad_target_2: info.especialidad_target_2,
					date: new Date(),
				},
			})
			.then(() => {
				updateUser(user);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const storePostTestReview = (user_feedback) => {
		let field = `post_test_review_${user.enurmTargetYear}`;
		firebase
			.firestore()
			.collection('users')
			.doc(user.uid)
			.update({
				[field]: {
					...user_feedback,
					date: new Date(),
				},
			})
			.then(() => {
				firebase
					.firestore()
					.collection('reviews')
					.add({
						...user_feedback,
						date: new Date(),
						user: user.uid,
						userFirstName: user.firstName,
						userLastName: user.lastName,
						year: user.enurmTargetYear,
					});
			})
			.then(() => {
				updateUser(user);
			})
			.catch((err) => {
				console.info({
					...user_feedback,
					date: new Date(),
					user: user.uid,
					year: user.enurmTargetYear,
					[field]: field,
				});
				console.log(err);
			});
	};
	//#endregion

	useEffect(() => {
		!nextEnurm && getCurrentEnurm();
		// eslint-disable-next-line
	}, []);

	return (
		<AuthContext.Provider
			value={{
				loading,
				changeEmailAddress,
				// addNewTestQuestion,
				// createNewTest,
				deleteUserOnFailedSignup,
				freeTrialStart,
				freeTrialEnd,
				hasLatestWebAppVersion,
				user,
				userAuthState,
				updateCurrentTestSelections,
				updateNewUserInformationInFirestore,
				updateUser,
				updateWebApp,
				updateUserWebAppVersion,
				userTestRecords,
				getCurrentUserFromFirebaseAuth,
				getUserTestRecordsFromDB,
				getUserTestRecords,
				testSelections,
				isUserLoggedIn,
				newUserSignup,
				signOut,
				findUserByEmailAndMakeAdmin,
				sendVerificationEmail,
				storeFlashcardsResults,
				storeUserTestResults,
				storePostTestConsultation,
				storePostTestReview,
				resetPassword,
				updateUserUsingLocalStorage,
				updateToPaidViaStripe,
				webAppVersion,
			}}>
			{props.children}
		</AuthContext.Provider>
	);
};

export default AuthContextProvider;
