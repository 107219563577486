import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { paragraphEnd, removeHTMLTags } from '../../../utils/helpers';

// import Tag from '../quiz/Tag';

const QuestionLi = ({ question }) => {
	return (
		<div className='card'>
			<div className='card-content card-content-reduced-padding dark-text text-darken-3'>
				<div>
					<div className='card-title'>
						{question.imagePath && (
							<span>
								<i className='material-icons small'>image</i>
							</span>
						)}
						{question.category ? (
							<span>
								{question.hasOwnProperty('issues') ? (
									<span>
										<i
											className='material-icons'
											style={{ fontSize: '2.5rem', color: 'var(--red)' }}>
											priority_high
										</i>
									</span>
								) : (
									''
								)}
								<strong>Catogoría:</strong> {question.category}
							</span>
						) : (
							<span className='red-text'>
								<i className='material-icons' style={{ fontSize: '2.5rem' }}>
									warning
								</i>{' '}
								No Category Selected
							</span>
						)}

						{question.enurm ? (
							<span
								className={`badge badge-spacing ${
									question.enurm === 'prepmed'
										? 'bg-brand-2'
										: 'bg-alternate1-1'
								} white-text`}>
								{question.enurm.toUpperCase()}
							</span>
						) : (
							<span className='badge badge-spacing red white-text'>
								No label selected
							</span>
						)}
						{/* <span className='badge badge-spacing teal white-text'></span> */}
					</div>
				</div>

				<p>
					{question.question && question.question.includes('$_$')
						? paragraphEnd(question.question.split('$_$').join(' '))
						: paragraphEnd(question.question)}
					{/* {question.question && question.question.includes('$_$')
						? paragraphEnd(question.question.split('$_$').join(' '))
						: paragraphEnd(removeHTMLTags(question.question))} */}
					<small className='grey-text text-lighten-1 d-block'>
						{question.id}
					</small>
				</p>

				{question.issues && (
					<div
						className={`center white-text p-1 mb-2 ${
							question.issues.list.includes('isMissingTheExplanationSource') &&
							question.issues.list.length === 1 &&
							!question.issues.message
								? 'bg-warning'
								: 'bg-red'
						}`}>
						<b>Issues to Fix:</b>
						{question.issues.list &&
							question.issues.list.map((item) => {
								return <li key={item}>{item}</li>;
							})}
						{question.issues.message && (
							<p>
								<span className='material-icons'>comment</span>
								<strong>Note:</strong> {question.issues.message}
							</p>
						)}
					</div>
				)}

				<ul style={{ margin: '0' }}>
					{question.tags &&
						question.tags.map((tag) => {
							return (
								<li
									className='chip'
									key={uuidv4()}
									id='tags'
									style={{ margin: '0' }}>
									{tag}
								</li>
							);
						})}
				</ul>
			</div>
		</div>
	);
};

export default QuestionLi;
