// https://youtu.be/AtZGoueL4Vs?t=347k
import React from 'react';
import { Redirect } from 'react-router-dom';
import { PRODUCT_PAYPAL } from '../../../utils/store/context/types';
import image from '../../../assets/logo/ICONO_ROUND_BLUE.png';
import { isMobileScreen, scrollToTop } from '../../../utils/helpers';
import { logEvent } from '../../../utils/store/config/fbConfig';
import paypal from '../../../assets/badges/secured-payment-paypal.png';

const PaypalCheckout = (props) => {
	const HOST = window.location.host;
	const URL = process.env.REACT_APP_PAYPAL_PUBLIC;
	// const URL =
	// 	process.env.NODE_ENV === 'production'
	// 		? process.env.REACT_APP_PAYPAL_PUBLIC_LIVE
	// 		: process.env.REACT_APP_PAYPAL_PUBLIC_SANDBOX;

	const productPrice = props.location.state.price;

	const [product, setProduct] = React.useState({
		...PRODUCT_PAYPAL,
		price: props.location.state.price,
	});
	const [isPaidFor, setIsPaidFor] = React.useState(false);
	const [isLoaded, setIsLoaded] = React.useState(false);
	const [error, setError] = React.useState(null);
	const [pid, setPid] = React.useState(false);
	const paypalRef = React.useRef();

	React.useEffect(() => {
		scrollToTop();
		if (process.env.NODE_ENV === 'production') {
			logEvent('INITIATED_CHECKOUT_PAYPAL');
		}
		// Load PayPal Script
		const script = document.createElement('script');
		script.src = `https://www.paypal.com/sdk/js?client-id=${URL}&locale=es_DO`;
		script.addEventListener('load', () => setIsLoaded(true));
		document.body.appendChild(script);
	}, []);

	React.useEffect(() => {
		// console.info(isLoaded);
		if (isLoaded) {
			try {
				window.paypal
					.Buttons({
						style: {
							layout: 'vertical',
							color: 'blue',
							shape: 'rect',
							label: 'paypal',
						},
						createOrder: (data, actions) => {
							return actions.order.create({
								purchase_units: [
									{
										reference_id: 'prepmedrd',
										// description: product.description,
										amount: {
											// currency_code: 'USD',
											value: product.price,
											// value: '6000.00',
										},
										custom_id: 'prepmedrd',
										soft_descriptor: 'PREPMEDRD',
									},
								],
								application_context: { shipping_preference: 'NO_SHIPPING' },
							});
						},
						onApprove: async (data, actions) => {
							const order = await actions.order.capture();
							setPid(order.id);
							sessionStorage.setItem(
								'PaymentID',
								JSON.stringify({
									id: order.id,
									source: 'PayPal',
									price: product.price,
									currency: 'USD',
								})
							);
							console.log(order);

							setIsPaidFor(true);
						},
						onError: (err) => {
							setError(err);
							console.error(err);
						},
					})
					.render(paypalRef.current);
			} catch (error) {
				console.info(error);
			}
		}
	}, [isLoaded, product.description, product.price]);

	if (isPaidFor) {
		//? REDIRECT TO SUCCESS PAGE BUT NEED TO SET SESSION AS BELOW
		// Destruct the checkout session ID and give it a name of sessionID
		// const { id: sessionId } = checkoutSessionFromStripe;
		// sessionStorage.setItem('StripeSessionId', sessionId);
		return pid && <Redirect to={`/success/session_id/${pid}`} />;
	}

	return (
		<div className='container'>
			{error && (
				<div>
					Hubo un error en el proceso de pago. Por favor intente más tarde.
				</div>
			)}
			<div className={isMobileScreen ? 'container' : 'w-75 mx-auto'}>
				<div className='row'>
					<div className='center col m4 s12 '>
						<img
							alt='prepmedrd'
							src={image}
							width='150px'
							className='responsive-img mt-4'
						/>
					</div>
					<div className='col m8 s12'>
						<h3 className='center txt-brand2'>{product.name}</h3>
						<p>{product.description}</p>
					</div>
					<p className='center'>Monto a pagar: US${product.price}</p>
				</div>
				<div ref={paypalRef} />
			</div>
			{/* <!-- PayPal Logo --> */}
			<div className='center mb-4'>
				<img src={paypal} border='0' alt='PayPal Acceptance Mark' />
			</div>
		</div>
	);
};

export default PaypalCheckout;
