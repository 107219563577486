import React from 'react';
import { Link } from 'react-router-dom';
import { useStripe } from '@stripe/react-stripe-js';
import { fetchFromAPI } from './helpers';
// import { firebaseCloudFunctions } from '../../../utils/store/config/fbConfig';
import { PRODUCT, PRODUCT_PAYPAL } from '../../../utils/store/context/types';
import ProgressBar from '../../Helpers/ProgressBar';
import LoadingFull from '../../Helpers/LoadingFull';
import {
	checkValidation,
	showInvalidMessage,
} from '../../../utils/validation_helpers';

import { PAYPAL_CHECKOUT } from '../../../utils/ROUTES';
import { nextTestDate } from '../../../utils/helpers';
import { logEvent } from '../../../utils/store/config/fbConfig';
import TrustBadges from '../../public/Homepage/TrustBadges';
import DiscountOffer from './DiscountOffer';
import FreeTrial from './FreeTrial';
import { AuthContext } from '../../../utils/store/context/authContext';
import { AdminContext } from '../../../utils/store/context/adminContext';

function MainCheckout() {
	const { user } = React.useContext(AuthContext);
	const { enurmData, getCurrentENURMData, getMarketingData, marketing } =
		React.useContext(AdminContext);

	// STATE
	const [loading, setLoading] = React.useState(false);
	const [discountValid, setDiscountValid] = React.useState(true);
	const [applyDiscount, setApplyDiscount] = React.useState(false);
	const [stripeProduct, setStripeProduct] = React.useState({});
	// const [product, setProduct] = React.useState(PRODUCT);
	const [displayPrice, setDisplayPrice] = React.useState();
	const [paypalPrice, setPaypalPrice] = React.useState(marketing.pricePaypal);
	const [stripePrice, setStripePrice] = React.useState(marketing.priceStripe);
	const disc = React.useRef('');

	React.useEffect(() => {
		// Todo: successful registration
		getCurrentENURMData();
		getMarketingData();

		return () => {
			setLoading(false);
		};
	}, []);

	React.useEffect(() => {
		setPaypalPrice(marketing.pricePaypal);
		setStripePrice(marketing.priceStripe);
		setStripeProduct({ ...PRODUCT, amount: marketing.priceStripe });
		getStripePrice();
	}, [marketing]);

	const stripe = useStripe();

	// const priceArray = (product.amount / 100).toString().split('');
	// const priceArray = (marketing.priceStripe / 100).toString().split('');
	// const y = priceArray.shift();
	// priceArray.unshift(y, ',');
	// const precioTotal = priceArray.join('');

	const getStripePrice = (price = marketing.priceStripe) => {
		const priceArray = (price / 100).toString().split('');
		const y = priceArray.shift();
		priceArray.unshift(y, ',');
		const precioTotal = priceArray.join('');
		setDisplayPrice(precioTotal);
	};

	const handleCheckout = async () => {
		if (process.env.NODE_ENV === 'production') {
			logEvent('INITIATED_CHECKOUT_STRIPE');
		}
		setLoading(true);
		const body = { line_items: [stripeProduct] };

		// Passes line_items to API to get sessionID
		const checkoutSessionFromStripe = await fetchFromAPI('checkouts', {
			body,
		});
		// console.info('STRIPE CHECKOUT SESSION:', checkoutSessionFromStripe);

		// Destruct the checkout session ID and give it a name of sessionID
		const { id: sessionId } = checkoutSessionFromStripe;
		// sessionStorage.setItem('PaymentID', sessionId);
		localStorage.setItem(
			'PaymentID',
			JSON.stringify({
				id: sessionId,
				source: 'Stripe',
				price: displayPrice,
				currency: 'DOP',
			})
		);

		// Tells Stripe to trigger the checkout session
		const { error } = await stripe.redirectToCheckout({
			sessionId,
		});

		if (error) {
			setLoading(false);
			console.log('ERROR:', error);
		}
	};

	const handleDiscount = (e) => {
		checkValidation(e);
		if (disc.current.value.toLowerCase() === '102620$llc') {
			setStripeProduct({ ...PRODUCT, amount: 6099 });
			setPaypalPrice(0.99);
			setApplyDiscount(true);
		} else if (disc.current.value.toLowerCase() === 'xrp22prepmed') {
			setDisplayPrice('$2,099.99');
			setStripeProduct({ ...PRODUCT, amount: 209999 });
			setPaypalPrice(34.99);
			setApplyDiscount(true);
		} else {
			setDiscountValid(false);
		}

		// if (disc.current.value.toLowerCase() === 'rumboalenurm') {
		// 	setProduct({ ...PRODUCT, amount: 150000 });
		// 	setPaypalPrice(24.99);
		// 	setApplyDiscount(true);
		// } else {
		// 	setDiscountValid(false);
		// }

		// if (disc.current.value.toLowerCase() === 'enurm2021') {
		// 	setProduct({ ...PRODUCT, amount: 100000 });
		// 	setPaypalPrice(17.99);
		// 	setApplyDiscount(true);
		// } else {
		// 	setDiscountValid(false);
		// }
	};

	return (
		<React.Fragment>
			{loading ? (
				<LoadingFull message={'De vuelta en breve...'} />
			) : (
				<div className='container mt-5'>
					{user.freeTrialExpired ? (
						<h3 className='txt-brand2'>
							<i className='material-icons fsize-larger mr-2'>check_circle</i>Tu
							acceso gratuito ha terminado...
						</h3>
					) : (
						<h3 className='txt-brand2'>
							<i className='material-icons fsize-larger mr-2'>check_circle</i>Tu
							cuenta fue registrada...
						</h3>
					)}
					<ProgressBar width={100} percent={50} />
					<div className='mt-4 fsize-medium'>
						{user.freeTrialExpired ? (
							<>
								<p>
									Llegaste al límite de tiempo concedido o llegaste al límite de
									exámenes permitidos. Esperamos te haya gustado la experiencia
									de usar nuestra plataforma y que hayas podido ver los
									beneficios de todo lo que tenemos para ofrecer. Para continuar
									usando nuestros servicios solo tienes que completar tu pago y
									una vez sea procesado tendrás acceso a nuestra plataforma
									completa que incluye:
								</p>
							</>
						) : (
							<p>
								¡Felicidades! Has tomado una excelente decisión para tu futuro
								profesional. Una vez tu pago sea procesado tendrás acceso a
								nuestra plataforma completa que incluye:
							</p>
						)}
						<ul className='normal-bullets ml-2'>
							<li>
								Una base de datos con <strong>más de 1,000 preguntas</strong> de
								material actualizado y enfocado 100% en el ENURM
							</li>
							<li>
								La facilidad de hacer <strong>simulaciones a tu ritmo</strong> y
								desde la comodidad de tu hogar
							</li>
							<li>
								Flashcards para ayudarte a <strong>memorizar conceptos</strong>{' '}
								necesarios
							</li>
							<li>
								Estadísticas para determinar tus{' '}
								<strong>puntos fuertes y débiles</strong>, y así puedas mejorar
								tu desempeño
							</li>
						</ul>
						<p>
							Para tener acceso a nuestra plataforma y todo lo que ofrecemos el
							precio regular es de RD$ 6,000 (o US$107.99) pero por motivo de
							apertura para el ENURM 2022 estamos ofreciendo acceso por un solo
							pago de US${paypalPrice} (aproximadamente {displayPrice}) a los
							primeros 100 en inscribirse.
						</p>
						<p>
							Debes completar tu pago lo antes posible para garantizar este
							precio. Este te cubre el acceso a PrepMedRD hasta la fecha en que
							se otorgue el ENURM {nextTestDate()}. Elige tu forma de pago para
							continuar.
						</p>
					</div>

					{marketing.showDiscount && (
						<DiscountOffer
							priceStripe={displayPrice}
							pricePaypal={paypalPrice}
						/>
					)}

					{/* //~ DISCOUNT INPUT */}
					<form className='row'>
						<div className='input-field col s12 m4 offset-m8'>
							<i className='material-icons prefix txt-brand2'>money_off</i>
							<label htmlFor='discount' onClick={() => disc.current.focus()}>
								Código de Descuento
							</label>
							<input
								type='text'
								id='discount'
								ref={disc}
								onChange={handleDiscount}
								disabled={applyDiscount}
								minLength={2}
								pattern='\S{4,20}'
								onInvalid={(e) =>
									showInvalidMessage(e, 'El código ingresado es inválido.')
								}
								autoComplete='off'
							/>
						</div>

						<div className='col s12 right-align'>
							{!discountValid && !applyDiscount && (
								<p className='txt-danger'>
									El código de descuento que entraste no existe.
								</p>
							)}
							{applyDiscount && (
								<p className='txt-success'>
									¡Código valido! La oferta actual es un solo pago de{' '}
									<strong>
										RD$
										{displayPrice}
									</strong>{' '}
								</p>
							)}
						</div>
					</form>

					{/* // ~ PAYMENT BUTTONS */}
					<div className='row'>
						<div className=' col m6 s12'>
							<div className='left-align center-on-small-only '>
								<button
									className='btn btn-large bg-brand w-m-100'
									onClick={() => handleCheckout(stripeProduct)}
									disabled={PRODUCT.quantity < 1}>
									{/* <i className='fab fa-cc-stripe right'></i> */}
									Pago con Tarjeta
								</button>
								<p>
									*No acepta tarjetas del <strong>Banco Popular</strong>{' '}
								</p>
							</div>
						</div>
						<div className=' col m6 s12'>
							<div className='right-align center-on-small-only'>
								<Link
									to={{
										pathname: PAYPAL_CHECKOUT,
										state: { price: paypalPrice },
										// state: { price: applyDiscount ? paypalPrice : '107.99' },
									}}
									className='btn btn-large bg-brand w-m-100'>
									Pago con{' '}
									<i
										className='fab fa-cc-paypal '
										style={{ verticalAlign: 'top' }}></i>
								</Link>
								<p>*Acepta todas las tarjetas</p>
							</div>
						</div>
					</div>
					<TrustBadges />
					<FreeTrial />
					<p style={{ fontSize: 'small' }}>
						Nota: Además, usted acepta nuestra política que establece su derecho
						a devolución de hasta un 75% de sus fondos si este es solicitado
						antes de concluir un periodo de 72 horas de instrito/a en el sitio
						web www.prepmedrd.com.
					</p>
				</div>
			)}
		</React.Fragment>
	);
}

export default MainCheckout;
