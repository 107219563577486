import React, { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { FirestoreContext } from '../../../utils/store/context/firestoreContext';
import { AuthContext } from '../../../utils/store/context/authContext';
import Historial from './Historial';
// import Stats from './Stats';
import { GRADE_CONSULTATION, STRIPE_CHECKOUT } from '../../../utils/ROUTES';
import { scrollToTop } from '../../../utils/helpers';
import NewFeature from './NewFeature';
import { AdminContext } from '../../../utils/store/context/adminContext';
// import PostTestConsultationSurvey from '../surveys/PostTestConsultationSurvey.js';
// import ReviewRequest from '../surveys/ReviewRequest';
import PostTestDashboard from './PostTestDashboard';
import LoadingSection from '../../Helpers/LoadingSection';
import LoadingFull from '../../Helpers/LoadingFull';
// import LoadingFull from '../../Helpers/LoadingFull';

const UserDashboard = () => {
	const {
		user,
		webAppVersion,
		userTestRecords,
		getUserTestRecords,
		updateWebApp,
	} = useContext(AuthContext);

	const { getQuestions, questions, getCategories } =
		useContext(FirestoreContext);

	const {
		//! No need to call getCurrentENURMData since it's already called in Navbar.js

		enurmData,
	} = useContext(AdminContext);

	const [loading, setLoading] = React.useState(true);

	useEffect(() => {
		// setLoading(true);
		// getCurrentUserFromFirebaseAuth();

		const getAllData = async () => {
			await getQuestions();
			await getCategories();
			getUserTestRecords();
		};

		webAppVersion?.number > 0 && getAllData();
		scrollToTop();
		/* eslint-disable-next-line */
	}, [webAppVersion]);

	useEffect(() => {
		scrollToTop();
		// user.hasOwnProperty('enurmTargetYear') &&
		// 	getCurrentENURMData(`enurm_${user.enurmTargetYear}`);
		// eslint-disable-next-line
	}, [user]);

	useEffect(() => {
		enurmData?.hasOwnProperty('testResultsOut') && setLoading(false);
	}, [enurmData]);

	useEffect(() => {
		setLoading(false);
	}, [questions, userTestRecords]);

	if (loading) {
		return <LoadingFull />;
	}

	//~ENURM results are out
	if (enurmData?.testResultsOut) {
		// if (true) {
		return (
			<main className='dashboard'>
				<div className='container'>
					{(!user.post_test_consultation || user.post_test_review) && (
						<div className='col m6 s12'>
							<h2 className='txt-brand2'>!Hola {user.firstName}!</h2>
						</div>
					)}

					<PostTestDashboard />
				</div>
				{user.post_test_consultation && user.post_test_review && (
					<section className='section-2'>
						<div className='container'>
							<div className='center'>
								¡Gracias por formar parte del grupo PrepMedRD 2021!
							</div>
						</div>
					</section>
				)}
			</main>
		);
	}

	//~ENUERM in proceess
	if (!enurmData?.testResultsOut) {
		return (
			<main className='dashboard'>
				<section className={`section-1 ${user.freeTrialActive && 'pt-0'}`}>
					<div className='container'>
						{/* //~SHOW IF WEBSITE UPDATE */}
						{updateWebApp && <NewFeature />}

						{/* //~SHOW CONSULTATION */}
						{!user.freeTrialActive && (
							<div className='card-panel bg-alternate1-white py-1'>
								<h5 className='txt-alternate1'>
									<i className='material-icons fsize-larger mr-2'>
										announcement
									</i>
									¿Quieres saber con qué nota entraron en años anteriores a tu
									especialidad de preferencia?
								</h5>
								{/* <p className='txt-black my-1'>
									Nuestra herramienta de consulta te permite ver las notas de 2
									especialidades de tu elección y con qué notas entraron en los
									últimos 5 años. Tener esta información ahora te servirá para
									motivarte a estudiar y mejorar aún más antes del examen.
								</p> */}
								<p>
									Consulta nuestra base de datos para visualizar la puntuación
									de los ganadores de los últimos 5 años de en 2 especialidades
									de tu elección.{' '}
								</p>
								<p>
									Puedes utilizar esta información para guiar tu preparación
									antes del examen y planificar un concurso de plaza exitoso.
								</p>
								<div className='right-align'>
									<Link to={GRADE_CONSULTATION} className='btn bg-alternate1-1'>
										<i className='material-icons right'>send</i>
										Consulta Ahora
									</Link>
								</div>
							</div>
						)}

						{/* //~SHOW TO FREE TRIAL USERS */}
						{user.freeTrialActive && (
							<div className='card-panel bg-alternate1-white py-1'>
								<h5 className='txt-alternate1'>
									<i className='material-icons fsize-larger mr-2'>
										announcement
									</i>
									Tu cuenta está en un período de prueba gratis.
								</h5>
								<p className='txt-black my-1'>
									Como cuenta de prueba{' '}
									<strong>solo tendrás acceso al ENURM 2020</strong>. Cuando
									estés list@ para ser un usuario premium con acceso a todo
									nuestro material, por favor, usa el botón de "Completar Pago"
									para continuar.
								</p>
								<div className='right-align'>
									<Link to={STRIPE_CHECKOUT} className='btn bg-alternate1-1'>
										<i className='material-icons right'>send</i>
										COMPLETAR PAGO
									</Link>
								</div>
							</div>
						)}
						<div className='row'>
							<div className='col m6 s12'>
								<h2 className='txt-black'>!Hola {user.firstName}!</h2>
								<p>
									¿Estás listo para empezar? Nuestro sistema te permite
									evaluarte en varias modalidades:
								</p>
								<ol>
									<li>Pruebines de 50 preguntas</li>
									<li>Simulaciones de 100 preguntas</li>
									<li>Otra cantidad de preguntas</li>
								</ol>
								<p>
									Tienes la opción adicional de elegir límite de tiempo o "modo
									tutor". El modo tutor te explica la respuesta correcta al
									momento de la selección. De no utilizarlo, podrás ver la
									respuesta correcta al terminar tu evaluación.
								</p>
							</div>
							{/* //! LINK BUTTONS */}
							<div
								className='col m5 offset-m1 s12'
								style={{ paddingTop: '3rem' }}>
								<h4 className='center txt-black'>Examínate</h4>
								<div className='d-inline align-center'>
									{/* Pruebín */}
									<Link
										to='/elige'
										className='d-block pruebin-link white-text bg-brand-1'>
										Pruebín
									</Link>
									{/* Simulación */}
									<Link
										to={{
											pathname: '/elige',
											state: { testLength: 100, testType: 'simulación' },
										}}
										className='d-block simulacion-link white-text bg-alternate2 '>
										Simulación
									</Link>

									{/* Otro */}
									<Link
										to={{
											pathname: '/elige',
											state: { testLength: 'otro', testType: 'otro' },
										}}
										className='d-block tutor-link white-text bg-alternate1'>
										Personalizado
									</Link>
									{/* Modo Tutor */}
									{/* <Link
						to={{
							pathname: '/elige',
							state: { useTutorMode: true },
						}}
						className='d-block tutor-link white-text bg-alternate2'>
						Modo Tutor
					</Link> */}
								</div>
							</div>
						</div>
					</div>
				</section>

				{questions && userTestRecords && <Historial />}
			</main>
		);
	}

	return (
		<LoadingSection
			padding='5rem'
			message='Si estás viendo este mensaje por favor contactanos...'
		/>
	);
};

export default UserDashboard;
