import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FirestoreContext } from '../../../utils/store/context/firestoreContext';
import { redirectToHome, scrollToID, toDateTime } from '../../../utils/helpers';

import parse from 'html-react-parser';
import EditModal from './modals/EditModal';
import ReportAnIssueModal from '../quiz/modals/ReportAnIssueModal';
import Explanation from '../quiz/questionaire/Explanation';
import {
	QUESTION_LIST,
	// QUESTION_DETAILS_DARK
} from '../../../utils/ROUTES';

// COMPONENTS
import ViewEditImage from '../questions/question-details/ViewEditImage';
import ImageUpload from '../questions/question-create/ImageUpload';
import NeedsImage from '../questions/question-create/NeedsImage';
import NeedsVideo from '../questions/question-create/NeedsVideo';
import EditLabelTags from '../questions/EditLabelTags';
import { AuthContext } from '../../../utils/store/context/authContext';

const QuestionDetails = (props) => {
	const {
		deleteQuestionField,
		questions,
		updateQuestionbyKey,
		getImageURL,
		imageURL,
	} = useContext(FirestoreContext);

	const { user } = useContext(AuthContext);
	const [showEdit, setShowEdit] = useState(false);

	const localQuestions = JSON.parse(localStorage.getItem('prepmed-questions'));

	/*eslint-disable*/
	useEffect(() => {
		M.AutoInit();
		scrollToID('#root');

		if (!user?.isAdmin) return redirectToHome();

		if (question?.hasOwnProperty('imagePath')) {
			let url = getImageURL(question.imagePath);
		}

		if (!question) return window.history.back();
	}, []);
	/*eslint-enable*/

	const questionID =
		props.hasOwnProperty('match') && Object.keys(props.match).length !== 0
			? props.match.params.id
			: props.questionID;
	// const questionID = props.match.params.id;

	const filteredQuestions = questions.filter((question) =>
		// question.id === props.match.params.id;
		props.hasOwnProperty('match') && Object.keys(props.match).length !== 0
			? question.id === props.match.params.id
			: question.id === props.questionID
	);

	const question = filteredQuestions[0];

	const clearIssues = () => {
		deleteQuestionField(question.id, 'issues');
	};

	return question ? (
		<div className='container section quiz-details' style={{ width: '95%' }}>
			{/* REPORTED ISSUES */}
			{question.issues && (
				<div className='center red darken-2 white-text p-2'>
					<h4>Issues to Fix:</h4>
					{question.issues.list &&
						question.issues.list.map((item) => {
							return <li key={item}>{item}</li>;
						})}
					{question.issues.message && (
						<p>
							<span className='material-icons'>comment</span>
							<strong>Note:</strong> {question.issues.message}
						</p>
					)}
					<p>
						All issues resolved?
						<span>
							<button className='btn ml-2 red darken-1' onClick={clearIssues}>
								<i className='material-icons'>delete</i> Clear All
							</button>
						</span>{' '}
					</p>
				</div>
			)}
			<div className='right-align'>
				{/* <Link
					className='btn btn-flat'
					to={{
						pathname: '/question-details-dark',
						state: { id: questionID },
					}}>
					Dark Mode
				</Link> */}
				<Link to={QUESTION_LIST} className='btn btn-flat'>
					<span className='material-icons'>dashboard</span>
					<span> Back to List</span>
				</Link>
				<div className='d-inline'>
					<ReportAnIssueModal
						update={updateQuestionbyKey}
						questionID={questionID}
					/>
				</div>
			</div>
			<div className='card z-depth-0'>
				{user.isAdmin && (
					<div style={{ position: 'sticky', top: '5vh' }}>
						{/* <div className='fixed-action-btn'> */}
						<button
							onClick={() => setShowEdit(!showEdit)}
							title='Edit Question'
							className='btn btn-floating right bg-brand'
							style={{
								margin: '0.10vmin',
								position: 'absolute',
								right: '-3vh',
							}}>
							<i className='material-icons'>edit</i>
							{/* Edit Question */}
						</button>
					</div>
				)}

				{/* Pregunta */}
				<div className='card-content'>
					{showEdit ? (
						<div className='card-panel br-2' id='question'>
							<EditModal
								triggerCSS={'grey-text lighten-2 z-depth-0 right modal-trigger'}
								update={updateQuestionbyKey}
								questionID={questionID}
								fieldKey={'question'}
								content={question.question}
							/>
							{parse(question.question)}
						</div>
					) : (
						<div className='card-panel br-2' id='question'>
							{/* <div className='right'>
								<ReportAnIssueModal
									update={updateQuestionbyKey}
									questionID={questionID}
								/>
							</div> */}

							{parse(question.question)}
						</div>
					)}

					{/* Image container */}
					{question.imagePath && question.showImageInQuestion && (
						<ViewEditImage
							showEdit={showEdit}
							questionID={questionID}
							question={question}
							imageURL={imageURL}
						/>
					)}

					{/* Answer and Options */}
					<ul className='collection'>
						<li
							className='collection-item'
							style={{ background: 'var(--success-light)' }}>
							{showEdit ? (
								<span style={{ color: 'var(--success)' }}>
									<EditModal
										triggerCSS={
											'grey-text lighten-2 z-depth-0 right modal-trigger'
										}
										update={updateQuestionbyKey}
										questionID={questionID}
										fieldKey={'answer'}
										content={question.answer}
									/>
									<i className='material-icons material-small-font'>
										check_circle
									</i>{' '}
									{question.answer}
								</span>
							) : (
								<span style={{ color: 'var(--success)' }}>
									<i className='material-icons material-small-font'>
										check_circle
									</i>{' '}
									{question.answer}
								</span>
							)}
						</li>
						<li className='collection-item'>
							{showEdit ? (
								<span id='option1'>
									<EditModal
										triggerCSS={
											'grey-text lighten-2 z-depth-0 right modal-trigger'
										}
										update={updateQuestionbyKey}
										questionID={questionID}
										fieldKey={'option1'}
										content={question.option1}
									/>
									{question.option1}
								</span>
							) : (
								<span>{question.option1}</span>
							)}
						</li>
						<li className='collection-item'>
							{showEdit ? (
								<span id='option2'>
									<EditModal
										triggerCSS={
											'grey-text lighten-2 z-depth-0 right modal-trigger'
										}
										update={updateQuestionbyKey}
										questionID={questionID}
										fieldKey={'option2'}
										content={question.option2}
									/>
									{question.option2}
								</span>
							) : (
								<span>{question.option2}</span>
							)}
						</li>
						<li className='collection-item'>
							{showEdit ? (
								<span id='option3'>
									<EditModal
										triggerCSS={
											'grey-text lighten-2 z-depth-0 right modal-trigger'
										}
										update={updateQuestionbyKey}
										questionID={questionID}
										fieldKey={'option3'}
										content={question.option3}
									/>
									{question.option3}
								</span>
							) : (
								<span>{question.option3}</span>
							)}
						</li>
					</ul>
				</div>
				{/* Explanation Panel */}
				<div
					className='card-panel br-2 white-text'
					style={{ background: 'var(--brand-3)' }}>
					<h5>Explicación:</h5>
					{showEdit ? (
						<div className='explanation'>
							<EditModal
								triggerCSS={
									'white-text lighten-2 right z-depth-0 modal-trigger'
								}
								materialSmall={false}
								update={updateQuestionbyKey}
								questionID={questionID}
								fieldKey={'explanation'}
								content={question.explanation}
							/>
							<Explanation currentQuestion={question} />
						</div>
					) : (
						<div className='explanation'>{parse(question.explanation)}</div>
					)}

					{/* Explanation Image container */}
					{question.imagePath && !question.showImageInQuestion && (
						<ViewEditImage
							showEdit={showEdit}
							questionID={questionID}
							question={question}
							imageURL={imageURL}
						/>
					)}

					{!question.imagePath && showEdit && (
						<div className='center w-75 m-auto'>
							<h6>
								<i className='material-icons small'>image</i> No image added,
								want to add one?
							</h6>
							<ImageUpload questionID={questionID} />
						</div>
					)}

					{/* Fuente de la Explicacion */}
					{question.fuente ? (
						<blockquote className='bl-white fuente'>
							{showEdit ? (
								<React.Fragment>
									<strong>
										<span className='material-icons material-small-font'>
											source
										</span>{' '}
										Fuente:
									</strong>
									<EditModal
										triggerCSS={'white-text right z-depth-0 modal-trigger'}
										materialSmall={false}
										update={updateQuestionbyKey}
										questionID={questionID}
										fieldKey={'fuente'}
										content={question.fuente}
									/>
								</React.Fragment>
							) : (
								<strong>
									<span className='material-icons material-small-font'>
										source
									</span>{' '}
									Fuente:
								</strong>
							)}
							<br />
							{parse(question.fuente)}
						</blockquote>
					) : showEdit ? (
						<React.Fragment>
							<strong>
								<span className='material-icons material-small-font'>
									source
								</span>{' '}
								Fuente:
							</strong>
							<EditModal
								triggerCSS={'white-text right z-depth-0 modal-trigger'}
								materialSmall={false}
								update={updateQuestionbyKey}
								questionID={questionID}
								fieldKey={'fuente'}
								content={question.fuente}
							/>
						</React.Fragment>
					) : (
						''
					)}
				</div>
				{/* Datos de la Pregunta */}
				<div className='card-panel z-depth-0 grey lighten-4 grey-text'>
					<h6>Datos de la Pregunta</h6>
					<div>ID: {questionID}</div>
					{/* Category */}
					<div>
						{question.category ? (
							showEdit ? (
								<span id='category'>
									Area: {question.category}{' '}
									<EditModal
										triggerCSS={'grey-text lighten-2 z-depth-0 modal-trigger'}
										materialSmall={true}
										update={updateQuestionbyKey}
										questionID={questionID}
										fieldKey={'category'}
										content={question.category}
									/>
								</span>
							) : (
								<span>Area: {question.category}</span>
							)
						) : (
							showEdit && (
								<span id='category'>
									No category selected, add one now?{' '}
									<EditModal
										triggerCSS={'grey-text lighten-2 z-depth-0 modal-trigger'}
										materialSmall={true}
										update={updateQuestionbyKey}
										questionID={questionID}
										fieldKey={'category'}
										content={''}
									/>
								</span>
							)
						)}
					</div>

					{/* SubCategory */}
					<div>
						{question.subCategory ? (
							showEdit ? (
								<span id='subCategory'>
									Especialidad: {question.subCategory}{' '}
									<EditModal
										category={question.category}
										triggerCSS={'grey-text lighten-2 z-depth-0 modal-trigger'}
										materialSmall={true}
										update={updateQuestionbyKey}
										questionID={questionID}
										fieldKey={'subCategory'}
										content={question.subCategory}
									/>
								</span>
							) : (
								<span>Especialidad: {question.subCategory}</span>
							)
						) : (
							showEdit && (
								<span id='subCategory'>
									No SubCategory selected, add one now?{' '}
									<EditModal
										category={question.category}
										triggerCSS={'grey-text lighten-2 z-depth-0 modal-trigger'}
										materialSmall={true}
										update={updateQuestionbyKey}
										questionID={questionID}
										fieldKey={'subCategory'}
										content={''}
									/>
								</span>
							)
						)}
					</div>

					{/* SubEspecialidad */}
					{/* {question.specialization && <span>{question.specialization}</span>} */}
					<div>
						{question.specialization ? (
							showEdit ? (
								<span id='specialization'>
									Sub-Especialidad: {question.specialization}{' '}
									<EditModal
										category={question.category}
										triggerCSS={'grey-text lighten-2 z-depth-0 modal-trigger'}
										materialSmall={true}
										update={updateQuestionbyKey}
										questionID={questionID}
										fieldKey={'specialization'}
										content={''}
									/>
								</span>
							) : (
								<span>Sub-Especialidad: {question.specialization}</span>
							)
						) : (
							showEdit && (
								<span id='specialization'>
									No hay sub-especialidad seleccionada, agrega una?{' '}
									<EditModal
										category={question.category}
										triggerCSS={'grey-text lighten-2 z-depth-0 modal-trigger'}
										materialSmall={true}
										update={updateQuestionbyKey}
										questionID={questionID}
										fieldKey={'specialization'}
										content={''}
									/>
								</span>
							)
						)}
					</div>

					{/* LABELS / TAGS*/}
					{user.isAdmin && (
						<EditLabelTags id={questionID} question={question} />
					)}
					{/* ENURM or PrepMed */}
					<div>
						{question.enurm ? (
							<div>
								<span>Pregunta (label): </span>
								{showEdit ? (
									<span id='enurm'>
										{question.enurm.toUpperCase()}{' '}
										<EditModal
											triggerCSS={'grey-text lighten-2 z-depth-0 modal-trigger'}
											materialSmall={true}
											update={updateQuestionbyKey}
											questionID={questionID}
											fieldKey={'enurm'}
											content={question.enurm}
										/>
									</span>
								) : (
									<span>{question.enurm.toUpperCase()}</span>
								)}
							</div>
						) : (
							<div>
								No ENURM or PREPMED labelselected, select one?{' '}
								<EditModal
									triggerCSS={'grey-text lighten-2 z-depth-0 modal-trigger'}
									materialSmall={true}
									update={updateQuestionbyKey}
									questionID={questionID}
									fieldKey={'enurm'}
									content={question.enurm}
								/>
							</div>
						)}
					</div>
					{/* Question Author and Editor */}
					<div>
						Author: {question.authorFirstName} {question.authorLastName}
					</div>
					{/* Date Created */}
					<div>
						Created On:{' '}
						{toDateTime(question?.createdOn?.seconds).toDateString()}
						{/* {localQuestions
							? toDateTime(question?.createdOn?.seconds).toDateString()
							: question?.createdOn?.toDate().toString()} */}
					</div>
					{/* Last edit author */}
					{question.lastEditBy && (
						<div>Last Edit By: {question.lastEditBy}</div>
					)}
					{/* Last edit date */}
					{question.lastEditDate && (
						<div>
							Last Edit On:{' '}
							{toDateTime(question?.createdOn?.seconds).toDateString()}
							{/* {localQuestions
								? toDateTime(question.lastEditDate.seconds).toString()
								: question.lastEditDate.toDate().toString()} */}
						</div>
					)}
				</div>
				{user.isAdmin && (
					<div className='row'>
						<NeedsImage id={questionID} question={question} />

						<NeedsVideo id={questionID} question={question} />
					</div>
				)}
			</div>

			{/* QUESTION REVIEWED AND READY */}
			{user.isAdmin && (
				<div className='center py-4'>
					{question.questionReviewedAndReady ? (
						<h5 className='d-inline mr-2 txt-success'>
							This question is reviewed and ready to go.
						</h5>
					) : (
						<h5 className='d-inline mr-2 txt-danger'>
							Is this question reviewed and ready?
						</h5>
					)}
					{question.questionReviewedAndReady ? (
						<i className='material-icons txt-success fsize-4 mb-2'>
							check_circle
						</i>
					) : (
						<button
							className='btn bg-brand'
							onClick={() =>
								updateQuestionbyKey(
									questionID,
									'questionReviewedAndReady',
									true
								)
							}>
							Yes, it's ready.
						</button>
					)}
				</div>
			)}
		</div>
	) : (
		<div className='container center'>
			<p>Found no matching ID...</p>
		</div>
	);
};

export default QuestionDetails;
