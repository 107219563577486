// I don't need debounce for PrepMed but the idea of it makes sense to add in when creating
// Got it from this tutorial: https://youtu.be/I250xdtUvy8?t=2141
// https://github.com/Zackazt/evernote-clone/blob/master/src/helpers.js

import { EMAIL_VERIFICATION } from './ROUTES';

export const nextTestDate = () => {
	let thisMonth = new Date().getMonth();
	let ny = new Date().getFullYear();
	if (thisMonth > 2) {
		return ny + 1;
	}
	return ny;
};

export default function debounce(a, b, c) {
	let d, e;
	return function () {
		function h() {
			d = null;
			c || (e = a.apply(f, g));
		}
		let f = this,
			g = arguments;
		return (
			clearTimeout(d), (d = setTimeout(h, b)), c && !d && (e = a.apply(f, g)), e
		);
	};
}

export function removeHTMLTags(str) {
	return str.replace(/<[^>]*>?/gm, '');
}

export function removeEmptyParagraphsbyQuill(str) {
	return str.replace(/(<p><br><\/p>)/g, '');
}

export const paragraphEnd = (string, limit = 200) => {
	const dots = '...';

	if (string) {
		if (string.length < limit) {
			return string;
		}
		if (string.length > limit) {
			string = string.substring(0, limit) + dots;
			return string;
		}
	}
};

export function secondsToHoursMinutesSeconds(totalTimeInSeconds) {
	totalTimeInSeconds = Number(totalTimeInSeconds);
	let h = Math.floor(totalTimeInSeconds / 3600);
	let m = Math.floor((totalTimeInSeconds % 3600) / 60);
	let s = Math.floor((totalTimeInSeconds % 3600) % 60);

	// let hDisplay = h > 0 ? h + (h === 1 ? ' hour, ' : ' hours, ') : '';
	// let mDisplay = m > 0 ? m + (m === 1 ? ' minute, ' : ' minutes, ') : '';
	// let sDisplay = s > 0 ? s + (s === 1 ? ' second' : ' seconds') : '';
	let hoursDisplay = h > 0 ? `${h}:` : '';
	let minutesDisplay = m < 10 ? `0${m}:` : m > 0 ? `${m}:` : '';
	let secondsDisplay = s < 10 ? `0${s}` : s > 0 ? s : '';
	return hoursDisplay + minutesDisplay + secondsDisplay;
}

export function toDateTime(secs) {
	var t = new Date(1970, 0, 1); // Epoch
	t.setSeconds(secs);
	return t;
}

export const refreshPage = () => {
	window.location.reload(false);
};

export const redirect = (page) => {
	// "page" should be a string with the path
	// redirect('/dashboard');
	window.location.replace(page);
};

export const handleError = (err) => {
	console.log(err);
};

export const shuffle = (array) => {
	for (let i = array.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[array[i], array[j]] = [array[j], array[i]];
	}
	return array;
};

export const scrollToID = (spot) => {
	// "spot" should be an element id, for example: '#add-questions-form'
	document
		.querySelector(`${spot}`)
		.scrollIntoView({ behavior: 'smooth', block: 'start' });
};

/**
 * No params needed, simply scrolls to top of the screen
 */
export const scrollToTop = () => {
	const top = document.querySelector('body');

	if (top !== null) {
		top.scrollIntoView({
			alignToTop: true,
			behavior: 'smooth',
		});
	}
};

/**
 * Formats dates in spanish
 */
export const customDateFormatInSpanish = (date) => {
	const monthsList = [
		'Enero',
		'Febrero',
		'Marzo',
		'Abril',
		'Mayo',
		'Junio',
		'Julio',
		'Agosto',
		'Septiembre',
		'Octubre',
		'Noviembre',
		'Diciembre',
	];
	const days = ['Dom', 'Lun', 'Mar', 'Mier', 'Jue', 'Vie', 'Sab'];

	const d = new Date(date);
	const year = d.getFullYear();
	const dayNum = d.getDate();
	const monthName = monthsList[d.getMonth()];
	const dayName = days[d.getDay()];

	const formattedDate = `${dayName}, ${dayNum} ${monthName} ${year}`;
	return formattedDate;
};
/**
 * Formats dates in numbers only
 */
export const customDateNumbersOnly = (date) => {
	const monthsList = [
		'01',
		'02',
		'03',
		'04',
		'05',
		'06',
		'07',
		'08',
		'09',
		'10',
		'11',
		'12',
	];
	const days = ['Dom', 'Lun', 'Mar', 'Mier', 'Jue', 'Vie', 'Sab'];

	const d = new Date(date);
	const year = d.getFullYear();
	const dayNum = d.getDate();
	const monthName = monthsList[d.getMonth()];
	const dayName = days[d.getDay()];

	const formattedDate = `${dayName}, ${dayNum} ${monthName} ${year}`;
	return formattedDate;
};

/**
 * Returns boolean
 */
export const isMobileScreen = () => {
	return window.innerWidth < 790;
};

export const deleteAllCookies = () => {
	console.info('Delete all cookies...');
	var cookies = document.cookie.split(';');

	for (var i = 0; i < cookies.length; i++) {
		var cookie = cookies[i];
		var eqPos = cookie.indexOf('=');
		var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
		document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
	}

	localStorage.clear();
	sessionStorage.clear();
	clearIndexedDB('prepmed-questions');
	clearIndexedDB('firebaseLocalStorageDb');
	clearIndexedDB('firebase-installations-database');
	window.location.reload(true);
};

/**
 * Helper function for deleteAllCookies() - clears indexed databases
 */
const clearIndexedDB = (db) => {
	var req = indexedDB.deleteDatabase(db);
	req.onsuccess = function () {
		console.log(db, 'Deleted database successfully');
	};
	req.onerror = function () {
		console.log(db, "Couldn't delete database");
	};
	req.onblocked = function () {
		console.log(
			db,
			"Couldn't delete database due to the operation being blocked"
		);
	};
};

export const redirectToHome = () => {
	window.location.replace('/');
};
export const redirectToDashboard = () => {
	window.location.replace('/dashboard');
};
export const redirectToVerifyEmail = () => {
	window.location.replace(EMAIL_VERIFICATION);
};
export const redirectToLogin = () => {
	window.location.replace('/acceder');
};

export const calculateAge = (dob) => {
	let diff_ms = Date.now() - dob.getTime();
	let age_dt = new Date(diff_ms);

	return Math.abs(age_dt.getUTCFullYear() - 1970);

	// console.log(calculate_age(new Date(1993, 1, 1)));
};
