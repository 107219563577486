import { openDB } from 'idb';
import { consoleInfo } from './debugging';
// export const loadItemFromIndexedDB = (storeName, objectStore, id) =>
let db;
export const createObjectStore = async (database, version, tableNamesArray) => {
	console.info({ database, version, tableNamesArray });
	try {
		db = await openDB(database, version, {
			upgrade(db) {
				console.info('DB open request UPGRADE NEEDED');
				for (const tableName of tableNamesArray) {
					if (db.objectStoreNames.contains(tableName)) {
						continue;
					}
					db.createObjectStore(tableName, {
						autoIncrement: true,
						keyPath: 'id',
					});
				}
			},
			blocked() {
				console.info(
					'DB open request BLOCKED: older versions of the database open on the origin. '
				);
				// …
			},
			blocking() {
				console.info(
					'DB open request BLOCKING: this connection is blocking a future version of the database from opening'
				);
				// …
			},
			terminated() {
				console.info('DB open request TERMINATED...');
				// …
			},
		});
		// console.info('DB openned...');
		// console.info({ db });
		let result = db;
		return result;
	} catch (error) {
		return false;
	}
};

export const getValue = async (tableName, id) => {
	const tx = db.transaction(tableName, 'readonly');
	const store = tx.objectStore(tableName);
	const result = await store.get(id);
	console.log('Get Data ', JSON.stringify(result));
	return result;
};

export const getAllValues = async (tableName) => {
	const tx = db.transaction(tableName, 'readonly');
	const store = tx.objectStore(tableName);
	const result = await store.getAll();
	// console.log('Get All Data', JSON.stringify(result));
	return result;
};

export const putValue = async (tableName, value) => {
	const tx = db.transaction(tableName, 'readwrite');
	const store = tx.objectStore(tableName);
	const result = await store.put(value);
	// console.log('Put Data ', JSON.stringify(result));
	return result;
};

export const putBulkValues = async (tableName, values) => {
	const tx = db.transaction(tableName, 'readwrite');
	const store = tx.objectStore(tableName);
	for (const value of values) {
		const result = await store.put(value);
		// console.log('Put Bulk Data ', JSON.stringify(result));
	}
	return getAllValues(tableName);
};

export const deleteValue = async (tableName, id) => {
	const tx = db.transaction(tableName, 'readwrite');
	const store = tx.objectStore(tableName);
	const result = await store.get(id);
	if (!result) {
		console.log('Id not found', id);
		return result;
	}
	await store.delete(id);
	console.log('Deleted Data', id);
	return id;
};

export const deleteDB = async (database) => {
	// let DBDeleteRequest = await window.indexedDB.deleteDatabase(database);

	// DBDeleteRequest.onerror = function (event) {
	// 	consoleInfo('Error deleting database.');
	// };

	// DBDeleteRequest.onsuccess = function (event) {
	// 	consoleInfo('Database deleted successfully');
	// 	// openDB(objectStore);
	// };
	// return DBDeleteRequest;
	await deleteDB(database, {
		blocked() {
			console.info(
				'DB open request BLOCKED: database already exists and there are open connections that don’t close in response to a versionchange event, the request will be blocked until they all close. '
			);
		},
	});
};

// ~ HOW TO USE IT
// const [book, setBook] = useState();
// const [books, setAllBooks] = useState();
// useEffect(() => {
// 	const runIndexDb = async () => {
// 		const indexedDb = new indexedDBHelper('test', 1);
// 		await indexedDBHelper.createObjectStore(['books', 'students']);
// 		await indexedDBHelper.putValue('books', { name: 'A Game of Thrones' });
// 		await indexedDBHelper.putBulkValue('books', [
// 			{ name: 'A Song of Fire and Ice' },
// 			{ name: 'Harry Potter and the Chamber of Secrets' },
// 		]);
// 		await indexedDBHelper.getValue('books', 1);
// 		await indexedDBHelper.getAllValues('books');
// 		await indexedDBHelper.deleteValue('books', 1);
// 		setBook(await indexedDBHelperHelper.getValue('books', 1));
// 		setAllBooks(await indexedDBHelperHelper.getAllValues('books'));
// 	};
// 	runIndexDb();
// }, []);
