import React, { Component } from 'react';
import PropTypes from 'prop-types';
import M from 'materialize-css';
import 'materialize-css/dist/css/materialize.min.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import SelectCategories from './select-categories/SelectCategories';
import SelectSubCategories from './select-categories/SelectSubCategories';
import SelectSpecialization from './select-categories/SelectSpecializations';
import ImageUpload from '../../questions/question-create/ImageUpload';

// Source: https://medium.com/@vaibhav1180/how-to-use-materialize-css-modal-in-react-53f9c85ba40d
class Modal extends Component {
	// TODO: Image in question or explanation (this can wait for 2021)
	// TODO: CATEGORY (include all 3 dropdowns)

	constructor(props) {
		super(props);
		this.state = {
			type: props.fieldKey,
			value: props.content,
			isChecked: props.isChecked,
		};
	}

	handleChange = (e) => {
		if (this.state.type === 'showImageInQuestion') {
			this.setState({
				value: !this.state.isChecked,
				isChecked: !this.state.isChecked,
			});
		}
		if (
			this.state.type === 'question' ||
			this.state.type === 'explanation' ||
			this.state.type === 'fuente'
		) {
			// console.info(e);
			this.setState({ value: e });
		}
		if (
			this.state.type === 'answer' ||
			this.state.type === 'option1' ||
			this.state.type === 'option2' ||
			this.state.type === 'option3'
		) {
			this.setState({ value: e.target.value });
		}

		if (this.state.type === 'enurm') {
			this.setState({ value: e.target.value });
		}

		if (this.state.type === 'category' || this.state.type === 'subCategory') {
			this.setState({ value: e.target.value });
		}
	};

	updateField = (e) => {
		console.info(this.state.value, this.state.type);
		if (this.state.type === 'showImageInQuestion') {
			return this.props.update(
				this.props.questionID,
				this.state.type,
				this.state.value
			);
		} else if (this.state.type === 'showImageInQuestion') {
			return this.props.update(
				this.props.questionID,
				this.state.type,
				this.state.value
			);
		} else {
			this.props.update(
				this.props.questionID,
				this.state.type,
				this.state.value.replace(/(<p><br><\/p>)/g, '')
			);
		}
	};

	componentDidMount() {
		const options = {
			onOpenStart: () => {
				// console.log('Open Start');
			},
			onOpenEnd: () => {
				// console.log('Open End');
			},
			onCloseStart: () => {
				// console.log('Close Start');
			},
			onCloseEnd: () => {
				// this.setState({ ...this.state, type: '' });
				// console.log('Close End');
			},
			inDuration: 250,
			outDuration: 250,
			opacity: 0.5,
			dismissible: false,
			startingTop: '4%',
			endingTop: '10%',
		};
		M.Modal.init(this.Modal, options);

		// let instance = M.Modal.getInstance(this.Modal);
		// instance.open();
		// instance.close();
		// instance.destroy();
	}

	render() {
		return (
			<span>
				{/* Modal link trigger */}
				<a
					className={this.props.triggerCSS}
					data-target={`edit${this.state.type}`}
					href='#!'>
					<i
						className={
							this.props.materialSmall
								? 'material-icons material-small-font'
								: 'material-icons'
						}>
						edit
					</i>
				</a>

				{/* Modal hidden body */}
				<div
					ref={(Modal) => {
						this.Modal = Modal;
					}}
					id={`edit${this.state.type}`}
					className='modal'>
					{/* If you want Bottom Sheet Modal then add 
                        bottom-sheet class to the "modal" div
                        If you want Fixed Footer Modal then add
                        modal-fixed-footer to the "modal" div*/}
					<div className='modal-content' style={{ padding: '0 24px ' }}>
						<h5 className='grey-text text-darken-4'>
							Editing: <strong>{this.props.fieldKey}</strong>
						</h5>
						{/* showImageInQuestion */}
						{this.state.type === 'showImageInQuestion' && (
							<div className='input-field'>
								<p>
									<label>
										<input
											type='checkbox'
											onChange={this.handleChange}
											id='showImageInQuestion'
											className={this.state.isChecked ? 'filled-in' : ''}
											checked={this.state.isChecked ? 'checked' : ''}
										/>
										<span>Show image in the question section?</span>
									</label>
								</p>
							</div>
						)}

						{/* //~ imagePath */}
						{this.state.type === 'imagePath' && (
							<ImageUpload questionID={this.props.questionID} />
						)}

						{(this.state.type === 'question' ||
							this.state.type === 'explanation' ||
							this.state.type === 'fuente') && (
							<div
								className='input-field black-text'
								style={{
									marginBottom: 0,
								}}>
								<ReactQuill
									theme='snow'
									value={this.state.value || ''}
									onChange={this.handleChange}
									// onBlur={this.handleChange}
									modules={{
										toolbar: [
											['bold', 'italic', 'underline', 'strike'],
											[{ align: [] }],
											[{ list: 'ordered' }, { list: 'bullet' }],
											[{ size: ['small', false, 'large', 'huge'] }],
											[{ color: [] }, { background: [] }],

											['clean'],
										],
									}}
								/>
							</div>
						)}
						{(this.state.type === 'answer' ||
							this.state.type === 'option1' ||
							this.state.type === 'option2' ||
							this.state.type === 'option3') && (
							<div className='input-field'>
								<input
									type='text'
									defaultValue={this.props.content}
									id={this.props.fieldKey}
									onChange={this.handleChange}
								/>
							</div>
						)}
						{this.state.type === 'enurm' && (
							<div className='input-field'>
								<select
									className='browser-default'
									id='enurm'
									defaultValue={this.props.content}
									onChange={this.handleChange}>
									<option value='prepmed'>PrepMep</option>
									<option value='enurm-2021-A'>ENURM 2021 A</option>
									<option value='enurm-2021-B'>ENURM 2021 B</option>
									<option value='enurm-2021-C'>ENURM 2021 C</option>
									<option value='enurm-2020'>ENURM 2020</option>
									<option value='enurm-2019'>ENURM 2019</option>
									<option value='enurm-2018'>ENURM 2018</option>
									<option value='enurm-2017'>ENURM 2017</option>
									<option value='enurm-2016'>ENURM 2016</option>
									<option value='enurm-2015'>ENURM 2015</option>
									<option value='enurm-2014'>ENURM 2014</option>
									<option value='enurm-2013'>ENURM 2013</option>
									<option value='enurm-2012'>ENURM 2012</option>
								</select>
								<label htmlFor='enurm'></label>
							</div>
						)}
						{this.state.type === 'category' && (
							<SelectCategories
								changeFn={this.handleChange}
								content={this.props.content}
							/>
						)}
						{this.state.type === 'subCategory' && (
							<SelectSubCategories
								changeFn={this.handleChange}
								content={this.props.content}
								categoryID={this.props.category}
							/>
						)}
						{this.state.type === 'specialization' && (
							<SelectSpecialization
								changeFn={this.handleChange}
								content={this.props.content}
								categoryID={this.props.category}
							/>
						)}
					</div>
					<div className='modal-footer' style={{ paddingTop: '0' }}>
						<a className='modal-close btn-flat' href='#!'>
							Close
						</a>

						<a
							className='modal-close btn-flat'
							href='#!'
							onClick={this.updateField}>
							<i className='material-icons right'>submit</i>
							Submit
						</a>
					</div>
				</div>
			</span>
		);
	}
}

Modal.propTypes = {
	update: PropTypes.func.isRequired,
	content: PropTypes.any,
	questionID: PropTypes.string.isRequired,
	fieldKey: PropTypes.string.isRequired,
	materialSmall: PropTypes.bool,
	triggerCSS: PropTypes.string.isRequired,
	category: PropTypes.string,
};
export default Modal;
