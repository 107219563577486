import React from 'react';

/**
 * Takes in 'onChange' prop
 */
const SelectENURMYear = ({ onChange }) => {
	const [startDate, setStartDate] = React.useState('');

	let enurmYear = {
		2021: '12/01/2020',
		2022: '10/01/2021',
	};

	React.useEffect(() => {
		onChange(startDate);

		// return () => {
		// 	cleanup;
		// };
		//eslint-disable-next-line
	}, [startDate]);

	// (e) => setReportYear(enurmYear[e.target.value])
	return (
		<div className='input-field col s12 m6'>
			{/* <label htmlFor='year'>Select a Year</label> */}
			<select
				name='year'
				id='year'
				className='browser-default'
				defaultValue='default'
				onChange={(e) => setStartDate(enurmYear[e.target.value])}>
				<option value='default' disabled>
					- Select a Year -
				</option>
				<option value='2021'>2021</option>
				<option value='2022'>2022</option>
			</select>
		</div>
	);
};

export default SelectENURMYear;
