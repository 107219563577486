import React from 'react';
import { Link } from 'react-router-dom';

const SalesSeasonClosed = () => {
	return (
		<div className='container'>
			<div className='center'>
				<h3 className='mt-4'>¡Importante!</h3>
				<div className='left-align my-4'>
					{/* <p style={{ maxWidth: '70%', margin: '0 auto' }} className='mb-2'>
						Por el momento no estamos aceptando nuevas inscripciones ya que
						estamos enfocados en completar ciertas mejoras basadas en la
						experiencia de los que tomaron el ENURM 2021. Pero no te preocupes,
						¡PrepMed vuelve con un aún mejor plataforma y contenido!
					</p> */}
					<p style={{ maxWidth: '70%', margin: '0 auto' }} className='mb-2'>
						Las inscripciones para prepararte a tomar el ENURM 2022 ya
						terminaron y estaremos cerrados hasta que empiece la temporada ENURM
						2023. En este momento estamos totalmente enfocados en brindarles la
						mejor experiencia posible a nuestros estudiantes. Si tienes alguna
						pregunta por favor ve a <Link to='/contactanos'>Contactanos</Link>.
					</p>
					<p style={{ maxWidth: '70%', margin: '0 auto' }} className='mb-2'>
						Si quieres prepararte para el ENURM 2023 con nosotros, lo mejor es
						anotarte en nuestra lista de espera visitando este enlace:
					</p>
				</div>
				<a
					className='btn bg-brand'
					href='https://mailchi.mp/7c089f556b99/9stb08xnsl'>
					<i className='material-icons right'>send</i>
					Lista de Espera ENURM 2023
				</a>
			</div>
		</div>
	);
};

export default SalesSeasonClosed;
